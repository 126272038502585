a {
    color: inherit;
    text-decoration: none;
}

.frame a:not([class]) {
    color: var(--primary-color);

    &:focus,
    &:hover {
        text-decoration: none;
    }

    img {
        border: none;
    }
}

.cke_editable a {
    color: var(--texthover-color);
}

p {
    margin: 0 0 15px;
}


form {
    margin: 20px 0;
}

table {
    border-collapse: collapse;
    width: 100%;
    border: none;
}

td, th {
    vertical-align: top;
    text-align: left;
}

td p {
    margin: 7px 0;
}

h1, h2, h3, h4, h5, h6 {
    margin: 15px 0 15px;
    font-weight: 200;
    color: inherit;

    a {
        color: var(--texthover-color);
    }
}

h1, .h1 {
    font-size: 35px;
    line-height: 1.2;
    text-align: center;
}

h2, .h2 {
    font-size: 30px;
    line-height: 1.2;
    text-align: center;
    font-weight: normal;
    margin-bottom: 25px;
}


h3, .h3 {
    font-size: 25px;
    line-height: 1.2;
    font-weight: bold;
}

h4 {
    font-size: 2.4rem;
    line-height: 3rem;
    @media (--xs-viewport) {
        font-size: 2.2rem;
        line-height: 2.6rem;
    }
}

h5 {
    font-size: 2.2rem;
    line-height: 3rem;
    @media (--xs-viewport) {
        font-size: 1.8rem;
        line-height: 2.4rem;
    }
}

h6 {
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: normal;
    @media (--xs-viewport) {
        font-size: 1.6rem;
        line-height: 2rem;
    }

    a {
        color: var(--warning-color);
    }

}

h1, h2, h3, h4, h5, h6 {

    & a {
        text-decoration: none;
    }
}

blockquote {
    margin: 20px 0;
    font-style: normal;
    font-size: 30px;
    line-height: 1.2;
    color: var(--primary-color);

    p {
        font-size: inherit;
        line-height: inherit;
    }
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

.frame > header {
    display: flex;
    flex-direction: column;

    .subheader {
        order: 1;
        text-align: center;
    }

    > *:first-child {
        order: 2;
    }
}

.subheader {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    display: table;
    margin: 0 auto;
    color: #FFF;
    background-color: var(--primary-color);
    padding: 3px 14px;
}

.text-uppercase {
    text-transform: uppercase;
}
