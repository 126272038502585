#breadcrumbs {
    width: 100%;
    font-size: 12px;
    font-style: italic;

    ol {
        margin: 20px 0;
        padding: 0;
        list-style: none;

        li {
            margin: 0;
            display: inline-block;
            padding: 0;
            position: relative;

            &:not(:last-child)::after {
                content: '/';
                margin: 0 5px;
            }

            a {
                color: var(--primary-textcolor);

                &.active {
                    color: var(--secondary-color);
                }

                &:hover,
                &:focus {
                    color: var(--texthover-color);
                }
            }
        }
    }
}