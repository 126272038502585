.tx-contentblocks {
    section.accordion {
        border: 2px solid var(--primary-color);

        &.open {
            & > header a::after {
                transform: translateY(-50%) rotateX(180deg);
                top: 50%;
            }
        }

        & > header {
            padding: 0;

            &::after {
                display: none;
            }

            h1, h2, h3, h4, h5, h6 {
                margin: 0;
            }

            a {
                display: block;
                color: var(--white-color);
                font-size: inherit;
                line-height: inherit;
                background: var(--primary-color);
                padding: 23px 70px 23px 20px;
                transition: 350ms ease-in background-color;

                &:hover,
                &:focus {
                    text-decoration: none;
                    background: var(--primary-color);
                }

                &::after {
                    transform: none;
                    font-family: FontAwesome;
                    display: inline-block;
                    content: '\f107';
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    font-size: 35px;
                    line-height: 1;
                    transform: translate(0, -50%);
                }
            }
        }

        .accordion {
            border-color: var(--secondary-color);

            & > header a {
                padding: 17px 70px 17px 20px;
                background: var(--secondary-color);

                &:hover,
                &:focus {
                    background: var(--secondary-color);
                }
            }
        }
    }
}

.google-map {
    max-width: 100%;
    height: 250px;
}

.horizontal-scroll-container {
    white-space: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: #FFF;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2), 0 -5px 15px rgba(0, 0, 0, 0.2);
    padding: 35px 0 28px;
    scrollbar-width: thin;
    scrollbar-color: #000;

    &::-webkit-scrollbar {
        height: 11px;
        background: #E5E5E5;
        border-top: 5px solid #FFF;
        border-bottom: 5px solid #FFF;
    }

    &::-webkit-scrollbar-thumb {
        background: #000;
        border-top: 5px solid #FFF;
        border-bottom: 5px solid #FFF;
    }

    img {
        opacity: 0.5;
        margin: 0 25px;
    }
}