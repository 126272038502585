#footer {
    background: var(--dark-color);
    color: #FFF;

    h1, h2, h3, h4, h5, h6 {
        color: inherit;
    }


    a {
        color: #FFF;

        &:hover,
        &:focus {
            text-decoration: underline;
            color: #FFF;
        }
    }

    .col-2,
    .col-headline {
        font-weight: 600;
    }

    .btn-primary {
        background-color: #FFF;
        border-color: #FFF;
        color: var(--dark-color);

        &:hover,
        &:focus {
            color: #FFF;
            border-color: #FFF;
            background-color: var(--dark-color);
        }
    }

    .footer-top {
        padding: 50px 15px 30px;
    }

    .footer-bottom {
        padding: 40px 0;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.2);

        nav span + span::before {
            content: '|';
            margin: 0 5px;
        }
    }
}

@media (--sm-viewport) {

    .footer-top {

        .footer-logo {
            margin: 0 auto 30px;
            display: block;
            width: 66px;
            height: auto;
        }

        nav,
        .tz-col {
            text-align: left;
        }

        .tz-col {
            padding-top: 15px;
            padding-bottom: 15px;
            margin-top: 15px;
            margin-bottom: 15px;
            border-top: 1px solid rgba(255, 255, 255, 0.2);
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }
    }

    #footer .footer-bottom {
        text-align: center;
    }
}
