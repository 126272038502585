.tx-powermail .nolabel > legend,
.tx-powermail .nolabel > label,
.tx-powermail .nolabel > h3
{
  display: none;
}

/* Fix for honeypot field */
.powermail_form div:last-child
{
  top: 0;
}

.powermail_fieldset
{
  min-width: inherit;
  width: 100%;
  border: none;
  .powermail_submit
  {
    float: right;
    margin: 20px 0;
    font-size: 2rem;
    @media (--xs-viewport)
    {
      width: 100%;
    }
    @media (--phone-viewport)
    {
      font-size: 1.6rem;
    }
  }
  .powermail_field
  {
    margin-bottom: 20px;
  }
  .powermail_fieldwrap
  {
    max-width: var(--form-field-width);
  }
  .powermail_fieldwrap:not(.powermail_fieldwrap_type_submit) .powermail_field
  {
    display: flex;
    flex-direction: column;
    .errorHolder
    {
      order: 10;
    }
  }
  select
  {
    font-size: 2rem;
    line-height: 2rem;
    @media (--phone-viewport)
    {
      font-size: 1.6rem;
      line-height: 1.6rem;
    }
  }
  .powermail_fieldwrap_type_input,
  .powermail_fieldwrap_type_textarea,
  .powermail_fieldwrap_type_date,
  .powermail_fieldwrap_type_file
  {
    position: relative;
    & > label
    {
      transition: 300ms all ease-in-out;
      position: absolute;
      pointer-events: none;
      text-overflow: ellipsis;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      margin: 15px;
      top: 6px;
      font-size: 2.2rem;
      line-height: 2.2rem;
      @media (--phone-viewport)
      {
        font-size: 1.6rem;
        &:not([title=""]) + div
        {
          label,
          input
          {
            max-width: calc(100% - 60px);
          }
        }
      }
      .mandatory
      {
        padding-left: 5px;
      }
    }
    input,
    textarea
    {
      &::-webkit-input-placeholder {
        color: transparent;
      }
      &:-moz-placeholder {
        color: transparent;
      }
      &::-moz-placeholder {
        color: transparent;
      }
      &:-ms-input-placeholder {
        color: transparent;
      }
    }
    &.focused
    {
      label
      {
        font-size: 1.3rem;
        letter-spacing: 0.02em;
        top: -10px;
      }
      &:not([class*="parsley-"])
      {
        color: var(--secondary-color);
        .formInputTxt, input[type=email], input[type=file], input[type=password], input[type=text], select, textarea
        {
          border-color: var(--secondary-color);
        }
      }
    }
    &.parsley-error
    {
      label,
      .powermail_field::before
      {
        color: var(--error-color);
      }
      .formInputTxt, input[type=email], input[type=file], input[type=password], input[type=text], select, textarea
      {
        border-color: var(--error-color);
      }
    }
    &.parsley-success
    {
      label,
      .powermail_field::before
      {
        color: var(--success-color);
      }
      .formInputTxt, input[type=email], input[type=file], input[type=password], input[type=text], select, textarea
      {
        border-color: var(--success-color);
      }
    }
  }
  .powermail_fieldwrap_type_radio,
  .powermail_fieldwrap_type_check,
  .powermail_fieldwrap_type_select,
  .powermail_fieldwrap_type_country
  {
    label
    {
      font-size: 2rem;
      margin-bottom: 10px;
      display: inline-block;
      @media (--phone-viewport)
      {
        font-size: 1.6rem;
      }
    }
    & > label
    {
      position: relative;
      padding-left: 0;
      margin-bottom: 10px;
      padding-top: 0;
      @media (--phone-viewport)
      {
        font-size: 1.6rem;
      }
      .mandatory
      {
        padding-left: 5px;
      }
    }
    .radio,
    .checkbox
    {
      position: relative;
    }
    .parsley-error
    {
      & > label
      {
        color: var(--error-color);
        &::before
        {
          background: var(--error-color);
        }
      }
    }
    .parsley-success
    {
      input:not(old):checked + label
      {
        color: var(--success-color);
        &::before
        {
          background: var(--success-color);
        }
      }
    }
  }
  .powermail_fieldwrap_type_select,
  .powermail_fieldwrap_type_country
  {
    &.parsley-error
    {
      select
      {
        border-color: var(--error-color);
        color: var(--error-color);
      }
    }
    &.parsley-success
    {
      select
      {
        border-color: var(--success-color);
      }
    }
  }
  .powermail_fieldwrap_type_date,
  .powermail_fieldwrap_type_file
  {
    @media (--xs-viewport)
    {
      label
      {
        max-width: calc(100% - 80px);
      }
    }
    &:hover,
    &:focus
    {
      color: var(--secondary-color);
    }
  }
  .powermail_fieldwrap_type_date
  {
    .calendar-button
    {
      width: 60px;
      top: 0;
      font-size: 0;
      height: 62px;
      line-height: 62px;
      position: absolute !important;
      right: 0;
      border: none !important;
      margin: 0 !important;
      padding: 0 !important;
      @media (--phone-viewport)
      {
        right: 0;
        left: auto;
      }
      &::after
      {
        display: none;
      }
      &::before
      {
        background: svg-load('../../../../../node_modules/@fortawesome/fontawesome-pro/svgs/light/calendar.svg', fill=var(--black-color)) no-repeat center;
        background-size: 20px !important;
        content: '';
        cursor: pointer;
        height: 62px;
        width: 100%;
        padding: 0;
        top: 0;
        position: absolute;
        transition: 300ms ease-in-out all;
      }
      &:hover,
      &:focus
      {
        color: var(--secondary-color);
        &::before
        {
          background: svg-load('../../../../../node_modules/@fortawesome/fontawesome-pro/svgs/light/calendar.svg', fill=var(--primary-color)) no-repeat center;
        }
      }
    }
    input,
    .parsley-errors-list
    {
      width: calc(100% - 60px);
    }
  }
  .powermail_fieldwrap_type_file
  {
    .upload-button
    {
      width: calc(100% - 60px);
      max-width: 100%;
      display: block;
      padding: 22px;
      background: var(--primary-color);
      color: var(--white-color);
      position: absolute;
      top: 22px;
      z-index: 1;
      text-align: center;
      &:hover,
      &:focus
      {
        text-decoration: none;
        background: var(--secondary-color);
      }
      span
      {
        position: relative;
        &::before
        {
          content: '';
          background: svg-load('../../../../../node_modules/@fortawesome/fontawesome-pro/svgs/light/upload.svg', fill=var(--white-color)) no-repeat center;
          background-size: 16px!important;
          position: relative;
          pointer-events: none;
          width: 20px;
          height: 16px;
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
    .remove-button
    {
      width: 60px;
      top: 100%;
      position: absolute;
      font-size: 0;
      right: 0;
      height: 66px;
      transform: translateY(-100%);
      &::before
      {
        content: '';
        background: svg-load('../../../../../node_modules/@fortawesome/fontawesome-pro/svgs/light/trash.svg', fill=var(--black-color)) no-repeat center;
        background-size: 20px!important;
        padding: 20px;
        cursor: pointer;
        height: 100%;
        top: 0;
        position: absolute;
        width: 100%;
      }
      &:hover,
      &:focus
      {
        &::before
        {
          background: svg-load('../../../../../node_modules/@fortawesome/fontawesome-pro/svgs/light/trash.svg', fill=var(--primary-color)) no-repeat center;
        }
      }
      span
      {
        display: none;
      }
    }
    label
    {
      font-size: 1.6rem !important;
      position: relative;
      margin: 0;
      top: 0;
    }
    &.parsley-success
    {
      .powermail_field
      {
        &::before
        {
          background: var(--success-color);
        }
        input
        {
          border-color: var(--success-color);
        }
      }
      label
      {
        color: var(--success-color);
      }
    }
    &.parsley-error
    {
      .powermail_field
      {
        &::before
        {
          background: var(--error-color);
        }
        input
        {
          border-color: var(--error-color);
          color: var(--error-color);
        }
      }
      label
      {
        color: var(--error-color);
      }
    }
    .powermail_field
    {
      overflow: hidden;
      position: relative;
      input[type="file"]
      {
        max-width: calc(100% - 60px);
        width: calc(100% - 60px);
        max-height: 65px;
      }
    }
    .parsley-errors-list
    {
      width: calc(100% - 60px);
    }
  }
  .parsley-errors-list
  {
    height: 0;
    padding: 0 20px;
    margin: 0;
    pointer-events: none;
    background: var(--error-color);
    max-width: var(--form-field-width);
    transition: 300ms ease-in all;
    &.filled
    {
      height: 30px;
      padding: 5px 20px;
      overflow: hidden;
    }
    li
    {
      margin: 0;
      list-style: none;
      color: var(--white-color);
      line-height: 20px;
      @media (--phone-viewport)
      {
        font-size: 1.6rem;
      }
    }
  }
}

/* Datepicker XDSOFT*/
.xdsoft_datetimepicker
{
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
  background: var(--white-color);
  color: var(--primary-textcolor);
  position: absolute;
  padding: 0;
  z-index: 9999;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: none;
  width: calc(var(--form-field-width) - 60px) !important;
  max-width: 100vw !important;
  margin-top: 10px;
  /*@media (--md-viewport)
  {
    width: calc(970px - 100px - 50px) !important
  }
  @media (--sm-viewport)
  {
    width: calc(100vw - 50px - 50px) !important;
    max-width: 667px !important;
  }
  @media (--phone-viewport)
  {
    width: calc(100vw - 70px) !important;
    max-width: calc(100vw - 70px) !important;
  }*/
  *
  {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 0
  }
  iframe
  {
    position: absolute;
    left: 0;
    top: 0;
    width: 75px;
    height: 210px;
    background: transparent;
    border: none
  }
  button
  {
    border: none !important
  }
  &.xdsoft_noselect
  {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    width: 100vw;
    &::selection
    {
      background: transparent
    }
    &::-moz-selection
    {
      background: transparent
    }
  }
  .xdsoft_datepicker
  {
    width: 100%;
    float: left;
    padding: 10px 40px;
    @media (--xs-viewport)
    {
      padding: 10px;
    }
    &.active
    {
      display: block;
    }
  }
  .xdsoft_timepicker
  {
    display: none;
  }
  .xdsoft_monthpicker
  {
    position: relative;
    text-align: center;
    padding: 25px 0;
    @media (--xs-viewport)
    {
      padding: 5px;
    }
    .xdsoft_prev,
    .xdsoft_next
    {
      float: left;
      cursor: pointer;
      display: block;
      height: 36px;
      outline: none;
      overflow: hidden;
      padding: 0;
      position: relative;
      white-space: nowrap;
      width: 36px;
      background: var(--primary-color);
      border-radius: 100%;
      border: 1px solid var(--primary-color);
      @media (--phone-viewport)
      {
        width: 26px;
        height: 26px;
      }
      &:hover
      {
        opacity: 0.7;
      }
      &::before
      {
        background: svg-load('../../../../../node_modules/@fortawesome/fontawesome-pro/svgs/light/chevron-left.svg', fill=var(--white-color)) no-repeat center;
        background-size: 16px !important;
        content: '';
        pointer-events: none;
        width: 36px;
        height: 36px;
        position: absolute;
        left: 0;
        top: 0;
        @media (--phone-viewport)
        {
          width: 26px;
          height: 26px;
          line-height: 26px;
          background-size: 12px !important;
        }
      }
    }
    .xdsoft_next
    {
      float: right;
      &::before
      {
        background: svg-load('../../../../../node_modules/@fortawesome/fontawesome-pro/svgs/light/chevron-right.svg', fill=var(--white-color)) no-repeat center;
      }
    }
    .xdsoft_label
    {
      display: inline;
      position: relative;
      z-index: 9999;
      margin: 0;
      padding: 5px 3px;
      line-height: 20px;
      font-weight: bold;
      background-color: var(--white-color);
      float: left;
      width: 182px;
      text-align: center;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 2rem;
      @media (--phone-viewport)
      {
        font-size: 1.4rem;
      }
      span
      {
        color: var(--primary-color);
      }
      & > .xdsoft_select
      {
        border: 1px solid var(--border-color);
        position: absolute;
        right: 0;
        top: 30px;
        z-index: 101;
        display: none;
        background: var(--white-color);
        max-height: 160px;
        overflow-y: hidden;
        &.xdsoft_monthselect
        {
          right: -7px
        }
        &.xdsoft_yearselect
        {
          right: auto;
          left: 2px
        }
        & > div > .xdsoft_option
        {
          color: var(--primary-color);
          padding: 2px 10px 2px 5px;
          &:hover
          {
            color: var(--white-color);
            background: var(--primary-color);
          }
          &.xdsoft_current
          {
            background: var(--white-color);
            color: var(--primary-color);
          }
        }
      }
    }
    .xdsoft_month,
    .xdsoft_year
    {
      text-align: right;
      width: calc(50% - 36px);
    }
    .xdsoft_year
    {
      text-align: left;
    }
  }
  .xdsoft_calendar
  {
    clear: both;
    margin: 0 -5px;
    table
    {
      border-collapse: collapse;
      width: 100%;
      td,
      th
      {
        width: 14.2857142%;
        color: var(--primary-textcolor);
        border-collapse: collapse;
        cursor: pointer;
        height: auto;
        padding: 15px 5px;
        font-size: 2rem;
        @media (--phone-viewport)
        {
          font-size: 1.2rem;
          padding: 5px;
        }
        &:hover,
        &:focus
        {
          div
          {
            color: var(--white-color) !important;
            background: var(--primary-color);
            border: 1px solid var(--primary-color);
          }
        }
        & > div
        {
          margin: 0 auto;
          width: 36px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          @media (--phone-viewport)
          {
            width: 22px;
            height: 22px;
            line-height: 22px;
          }
        }
        &.xdsoft_today
        {
          background: var(--white-color);
          & > div
          {
            color: var(--secondary-color);
            border: 1px solid var(--primary-color);
          }
        }
        &.xdsoft_default,
        &.xdsoft_current
        {
          border-radius: 100%;
          & > div
          {
            color: var(--white-color);
            background: var(--primary-color);
          }
        }
        &.xdsoft_other_month,
        &.xdsoft_disabled
        {
          opacity: 0.5
        }
        &.xdsoft_disabled:hover
        {
          color: inherit !important;
          background: inherit !important;
          box-shadow: inherit !important
        }
        &.xdsoft_other_month.xdsoft_disabled
        {
          opacity: 0.2
        }
      }
      th
      {
        font-weight: 700;
        text-align: center;
        cursor: default;
        text-transform: uppercase;
      }
    }
  }
  .xdsoft_scrollbar
  {
    position: absolute;
    width: 7px;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    & > .xdsoft_scroller
    {
      background: var(--border-color) !important;
      height: 20px;
      border-radius: 3px
    }
  }
  .xdsoft_scroller_box
  {
    position: relative
  }
}