.custom-card {
    background: #FFF;
    color: #000;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);

    .text {
        padding: 27px;
    }

    * {
        color: var(--primary-textcolor);
    }
}

.custom-card.bg-light {
    background-color: var(--light-color);
    box-shadow: none;
}

.card-img-wrap {
    overflow: hidden;
    text-align: center;
}

.card-img {
    transition: transform 300ms ease;
    margin: 0 auto;
}

.card-link {
    font-style: normal;
    color: #000;

    &:hover,
    &:focus {
        text-decoration: none;

        .card-img {
            transform: scale(1.06);
        }
    }
}

.colContent.col-eq-height > .frame-type-gridelements_pi1,
.colContent.col-eq-height .custom-card {
    height: 100%;
}

.col-md-4 .custom-card .text {
    padding: 20px;

    p:last-child {
        margin: 0;
    }
}

@media (--sm-viewport) {
    .colContent + .colContent > .custom-card,
    .colContent + .colContent > .card-link > .custom-card {
        margin-top: 3em;
    }

    .colContent + .colContent > .person-card {
        margin-top: 30px;
    }
}
