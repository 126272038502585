.fancybox-overlay
{
  background: none;
  &::before
  {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--background-color);
    opacity: 0.75;
    content: '';
  }
}
.fancybox-wrap
{
  max-width: 1030px;
  &:not(.fancybox-type-iframe) .fancybox-skin .fancybox-inner
  {
    height: auto !important;
  }
  &.fancybox-type-iframe .fancybox-skin .fancybox-close
  {
    width: 40px;
    height: 40px;
    background: var(--white-color);
    right: 1px;
    &::before
    {
      width:40px;
      height: 40px;
      padding: 10px;
    }
  }
}
.fancybox-skin
{
  border-radius: 0;
  .fancybox-inner
  {
    max-width: 1030px;
    .content
    {
      padding: 15px 30px;
    }
  }
  .fancybox-title
  {
    position: relative;
    background: none;
    color: var(--primary-textcolor);
    text-align: center;
    padding: 20px;
    display: block;
  }
  .fancybox-image
  {
    max-width: 1030px;
  }
  .fancybox-nav
  {
    height: calc(100% - 60px);
    top: 60px;
    span
    {
      visibility: visible;
      background-image: none;
      left: 0;
      width: 50px;
      height: 50px;
      transform: translate(-50%,-50%);
      @media (--sm-viewport)
      {
        transform: translate(-25%,-50%);
        width: 40px;
        height: 40px;
      }
      &::before
      {
        background: var(--primary-color) svg-load('../../../../../node_modules/@fortawesome/fontawesome-pro/svgs/light/chevron-left.svg', fill=var(--white-color)) no-repeat center;
        background-size: 50px !important;
        content: '';
        width: 50px;
        height: 50px;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 100%;
        border: 1px solid var(--white-color);
        @media (--sm-viewport)
        {
          width: 40px;
          height: 40px;
        }
      }
    }
    &.fancybox-next span
    {
      transform: translate(50%,-50%);
      right: 0;
      left: auto;
      @media (--sm-viewport)
      {
        transform: translate(25%,-50%);
      }
      &::before
      {
        content: '';
        background: var(--primary-color) svg-load('../../../../../node_modules/@fortawesome/fontawesome-pro/svgs/light/chevron-right.svg', fill=var(--white-color)) no-repeat center;
      }
    }
  }
  .fancybox-close
  {
    top: 0;
    right: 0;
    background: none;
    text-align: center;
    z-index: 8060;
    width: 60px;
    height: 60px;
    &::before
    {
      position: absolute;
      background: svg-load('../../../../../node_modules/@fortawesome/fontawesome-pro/svgs/light/times.svg', fill=var(--black-color)) no-repeat center;
      background-size: 25px !important;
      content: '';
      width: 60px;
      height: 60px;
      color: var(--primary-color);
      pointer-events: none;
      left: 0;
      top: 0;
      padding: 20px;
    }
  }
}