html, body {
    margin: 0;
    padding: 0;
    -ms-overflow-style: scrollbar;
    min-height: 100vh;
}

body {
    color: var(--primary-textcolor);
    font-family: var(--font-family);
    font-size: 17px;
    line-height: 1.4;
    margin-top: 97px;
}

::selection {
    background: var(--primary-color);
    color: var(--white-color);
}

.cke_editable {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 20px;
    color: var(--primary-textcolor);
    background: var(--white-color);
}

#site {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    #cms-content {
        flex-grow: 1;
    }
}

#cms-content img {
    max-width: 100%;
    height: auto;
}

.container {
    width: 100% !important;
    max-width: 1170px;
}

* {
    outline: none;
}

hr {
    background-size: 100% auto;
    border: none;
    padding: 15px 0;
}

ul, ol, dl {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 17px;
    list-style: none;

    &:not([class]),
    &.ce-bullets {
        li,
        dt {
            position: relative;

            &::before {
                background: var(--primary-textcolor);
                content: "";
                position: absolute;
                left: -10px;
                top: 10px;
                width: 5px;
                height: 5px;
                border-radius: 100%;
            }
        }
    }

    li,
    dt {
        margin-bottom: 5px;
    }
}

.ce-bodytext ul {
    padding: 0;

    li {
        padding-left: 37px;

        &::before {
            left: 0;
            top: 9px;
        }
    }
}

#overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
    display: none;
}

#loader {
    display: none;
    background: svg-load('../../Images/ajax-loader.svg') no-repeat center;
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    z-index: 11;
    width: 200px;
    height: 200px;
}

/*Safari Bugfix flex:wrap*/
.row {
    &:before {
        content: none;
    }

    &:after {
        content: '';
    }
}

/* Embeded Video Full Width */
.video-embed {
    width: 100%;
    display: block;
    position: relative;

    &::before {
        content: '';
        padding-top: calc(100% / 480 * 270);
        display: block;
    }

    .video-embed-item {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

/* Square Mobile Images */
.frame-square {
    position: relative;

    &:after {
        content: '';
        padding-top: 100%;
        display: block;
    }
}

@media (--xs-viewport) {
    .frame-square-xs {
        overflow: hidden;

        .imgPropScale {
            background-image: none !important;
            position: relative !important;
            height: auto !important;

            &::after {
                content: "";
                padding-top: 100%;
                display: block;
                width: 100vw;
                position: relative;
                top: 0;
                left: 0;
            }

            img {
                visibility: visible;
                height: 100%;
                width: auto;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
                position: absolute;
            }
        }
    }
}

/* Space before and after */
.frame-space-before-large {
    margin-top: 80px;
    @media (--xs-viewport) {
        margin-top: 50px;
    }
}

.frame-space-after-large {
    margin-bottom: 80px;
    @media (--xs-viewport) {
        margin-bottom: 50px;
    }
}

.frame-space-before-medium {
    margin-top: 50px;
    @media (--xs-viewport) {
        margin-top: 30px;
    }
}

.frame-space-after-medium {
    margin-bottom: 50px;
    @media (--xs-viewport) {
        margin-bottom: 30px;
    }
}

.frame-space-before-small {
    margin-top: 30px;
    @media (--xs-viewport) {
        margin-top: 20px;
    }
}

.frame-space-after-small {
    margin-bottom: 30px;
    @media (--xs-viewport) {
        margin-bottom: 20px;
    }
}

[class*="frame-space-before"] {
    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5 {
        margin-top: 0
    }
}

[class*="frame-space-after"] {
    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5 {
        margin-bottom: 0
    }
}

/* Image Helper */
.imgPropScale {

    & > img,
    & > a > img,
    & > picture > img {
        width: 100%;
        height: auto;
        display: block;
    }
}

.imgFullCover {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @media (--sm-viewport) {
        background-position: center top;
    }

    & > a {
        margin: 0 !important;
        border: none !important;
        padding: 0 !important;

        &:focus {
            outline: 0;
        }

        &::after {
            display: none;
        }
    }

    & > img,
    & > picture > img {
        visibility: hidden;
    }
}

.ce-intext .ce-bodytext *:first-child,
.ce-intext .ce-bodytext *:first-child > * {
    margin-top: 0;
}

.ce-gallery.ce-border-radius img {
    border-radius: 100%;
}

.table-responsive {
    min-height: .01%;
    overflow-x: auto;
}

@media (--xs-viewport) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid var(--border-color);

        & > table {
            margin-bottom: 0;

            & > thead,
            & > tbody,
            & > tfoot {
                & > tr {
                    & > th,
                    & > td {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

.gm-style > .gmnoprint:not(.gm-bundled-control),
.gm-style-cc,
.gm-svpc {
    display: none;
}

.gm-bundled-control > .gmnoprint {
    display: block;
}

.container.slim {
    max-width: 776px;
}

.bg-primary {
    background-color: var(--primary-color);
}

.bg-eisenstadt {
    background-color: var(--eisenstadt-color);
}

.bg-neusiedl {
    background-color: var(--neusiedl-color);
}

.bg-mittelburgenland {
    background-color: var(--mittelburgenland-color);
}

.bg-pinkafeld {
    background-color: var(--pinkafeld-color);
}

.bg-jennersdorf {
    background-color: var(--jennersdorf-color);
}

.bg-guessing {
    background-color: var(--guessing-color);
}

.bg-light {
    background-color: var(--light-color);
}

.tx-contentblocks-options-container.overlay-active {
    position: relative;
    color: #FFF;
    background-size: cover;
    background-position: center center;

    &::before {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
    }

    > div {
        position: relative;
        z-index: 1;
    }
}

.pad-box-1 {
    padding: 100px 0;
    @media (--sm-viewport) {
        padding: 50px 0;
    }
}

.pad-box-30 {
    padding: 30px 0;
}
