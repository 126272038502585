.page-navigation
{
  display: block;
  width: 100%;
  & > p
  {
    display: none;
  }
}

.f3-widget-paginator,
#solr-pagination {
	margin: 30px 0;
	padding: 0;
	list-style: none;
	vertical-align: middle;
	text-align: center;
	display: flex;
	justify-content: center;
	float: none !important;

	li {
		display: inline-block;
		margin: 0 5px;

		&.current {
			background: var(--primary-color) !important;
			color: var(--white-color);
			width: 30px;
			height: 30px;
			border-radius: 100%;
			border: 2px solid var(--primary-color);
			line-height: 26px;
			vertical-align: middle;
		}

		a {
			margin: 0;
			padding: 0;
			position: relative;
			text-decoration: none;
			list-style: none;
			vertical-align: middle;
			display: inline-block;
			width: 30px;
			height: 30px;
			line-height: 2.6rem;
			color: var(--primary-color);
			border: 2px solid var(--primary-color);
			border-radius: 100%;
			text-align: center;
			transition: 300ms ease-in-out all;

			&:hover,
			&:focus {
				background: var(--primary-color);
				color: var(--white-color);
			}
		}
    span
    {
      font-size: 1.6rem;
      line-height: 2.6rem;
    }
	}

	.next,
	.previous {
		img {
			display: none;
		}

		& > a {
			width: 30px;
			height: 30px;
			text-indent: -9999px;

			&::after {
				background: svg-load('../../../../../node_modules/@fortawesome/fontawesome-pro/svgs/light/chevron-right.svg', fill=var(--primary-color)) no-repeat center;
				background-size: 7px !important;
				content: '';
				position: absolute;
				left: 0;
				width: 100%;
				height: 100%;
				text-indent: 0;
			}
			&:hover,
			&:focus
			{
				&::after
				{
					background: svg-load('../../../../../node_modules/@fortawesome/fontawesome-pro/svgs/light/chevron-right.svg', fill=var(--white-color)) no-repeat center;
				}
			}
		}
	}
	.previous > a
	{
		&::after
		{
			background: svg-load('../../../../../node_modules/@fortawesome/fontawesome-pro/svgs/light/chevron-left.svg', fill=var(--primary-color)) no-repeat center;
		}
		&:hover,
		&:focus
		{
			&::after
			{
				background: svg-load('../../../../../node_modules/@fortawesome/fontawesome-pro/svgs/light/chevron-left.svg', fill=var(--white-color)) no-repeat center;
			}
		}
	}
}