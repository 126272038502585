.kesearch_searchbox {

    div.kesearchbox {
        display: table;
        padding-right: 32px;

        &::after {
            content: '\f002';
            display: inline-block;
            font-family: FontAwesome;
            background-color: var(--primary-color);
            color: #FFF;
            height: 32px;
            width: 32px;
            position: absolute;
            right: 0;
            top: 0;
            line-height: 32px;
            text-align: center;
            font-size: 20px;
        }
    }

    #ke_search_sword {
        border-radius: 0;
        position: static;
        border: 1px solid var(--primary-color);
        padding: 5px 40px 5px 9px;
    }

    #kesearch_submit {
        top: 0;
        right: 0;
        height: 32px;
        width: 32px;
        z-index: 5;
        opacity: 0;
        left: auto;
    }

    .resetbutt,
    .submitbutt {
        display: none;
    }

}

.kesearch_pagebrowser {
    ul li::before,
    .next,
    .prev {
        display: none !important;
    }
}