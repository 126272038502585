.news
{
  .news-list-view
  {
    display: flex;
    flex-wrap: wrap;
    .article
    {
      flex-basis: calc(100% / 3);
      padding: 0 15px;
      margin: 15px 0;
      a
      {
        background: var(--background-light-color);
        padding: 15px;
        display: block;
        transition: 350ms all cubic-bezier(.14,.75,.74,.41);
        color: var(--primary-textcolor);
        &:hover,
        &:focus
        {
          color: var(--primary-textcolor);
          box-shadow: var(--box-shadow);
        }
        .list-item-img-inner-wrap
        {
          margin-bottom: 15px;
        }
        .news-list-date
        {
          color: var(--secondary-color);
          font-size: 1.4rem;
          line-height: 1.4rem;
        }
      }
    }
  }
}