:root {
    --slick-arrow-color: var(--primary-color);
    --slick-dot-color: var(--secondary-color);
    --slick-dot-color-active: var(--primary-color);
    --slick-prev-character: var(--prev-arrow);
    --slick-next-character: var(--next-arrow);
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;

    [dir="rtl"] & {
        float: right;
    }

    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-list {
    .slick-loading & {
        background: var(--white-color) svg-load('../Images/ajax-loader.svg') no-repeat center;
    }
}

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 85px;
    width: 60px;
    line-height: 85;
    font-size: 0;
    cursor: pointer;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    z-index: 1;
    background: var(--primary-color);
    color: #FFF;

    &:hover, &:focus {
        outline: none;
    }

    &.slick-disabled:before {
        opacity: 0.3;
    }

    &:before {
        display: inline-block;
        font-family: FontAwesome;
        font-size: 44px;
        line-height: 27px;
    }
}

.slick-prev {
    left: 0;

    &::before {
        content: '\f104';
    }
}

.slick-next {
    right: 0;

    &::before {
        content: '\f105';
    }
}

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: relative;
    bottom: 0;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
        position: relative;
        display: inline-block;
        height: 12px;
        width: 12px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        &::before {
            display: none;
        }

        button {
            border: 0;
            background: transparent;
            display: block;
            height: 12px;
            width: 12px;
            outline: none;
            line-height: 0;
            font-size: 0;
            color: transparent;
            padding: 5px;
            cursor: pointer;

            &:hover, &:focus {
                outline: none;

                &:before {
                    opacity: 0.5;
                }
            }

            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 10px;
                height: 10px;
                background: svg-load('../../../../node_modules/@fortawesome/fontawesome-pro/svgs/light/circle.svg', fill=var(--slick-dot-color)) no-repeat center;
                background-size: 10px !important;
                content: '';
                line-height: 1rem;
                text-align: center;
                opacity: 1;
                border-radius: 100%;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        &.slick-active button:before {
            color: var(--slick-dot-color-active);
            opacity: 1;
            background: var(--slick-dot-color-active);
        }
    }
}

@media (--sm-viewport) {
    .slick-next,
    .slick-prev {
        width: 40px;
        height: 50px;

        &::before {
            font-size: 34px;
        }
    }
}
