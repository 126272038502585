.tiled-menu {
    text-align: center;
    margin-top: -50px;
    position: relative;
    z-index: 1;

    .row {
        display: flex;
        flex-wrap: wrap;

        > div > a {
            height: 100%;
            padding: 20px 60px;
            display: block;

            &:hover, &:focus {
                text-decoration: none;
                color: inherit;
            }
        }
    }

    > .row > div {
        margin-top: 30px;
    }

    img {
        width: 160px;
        height: auto;
    }

    .subheader {
        margin: 15px auto;
    }
}

.tiled-header-menu {
    display: flex;
    margin-top: -150px;
    position: relative;
    z-index: 1;
    flex-wrap: wrap;

    > a {
        width: 16.666666667%;
        text-align: center;
        padding: 20px;
        position: relative;

        &::after {
            content: ' ';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 20px;
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.16));
        }
    }

    > a:last-child::after {
        display: none;
    }

    img {
        width: 100px;
        height: auto;
        display: block;
        margin: 0 auto 17px;
    }
}

.tiled-header-menu,
.tiled-menu,
#fixed-tile-menu {
    .subheader {
        background-color: inherit;
        padding: 0;
        color: var(--primary-textcolor);
        line-height: 1;
    }
}

#sidebar {
    position: fixed;
    top: 50%;
    right: 0;
    z-index: 30;
    transform: translate(0, -50%);
}

#fixed-tile-menu::after {
    content: ' ';
    display: none;
    position: absolute;
    left: -12px;
    top: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 12px 14px 0;
    border-color: transparent var(--primary-color) transparent transparent;
    margin-top: -14px;
    z-index: 12;
}

#fixed-tile-menu > nav {
    padding: 30px 170px 30px 30px;
    display: none;
    position: absolute;
    top: -170px;
    right: 0;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    background-color: #FFF;
}

.fixed-tile-menu-open #fixed-tile-menu > nav,
.fixed-tile-menu-open #fixed-tile-menu::after {
    display: block;
}

#fixed-tile-menu > nav > div {
    display: flex;
    width: 280px;
    flex-wrap: wrap;
}

#fixed-tile-menu .overview-link {
    display: flex;
    align-items: center;
    flex-direction: row;
    text-align: left;
    width: 100%;

    span {
        margin: 0 0 15px 15px;
        line-height: 1.2;
    }
}

#fixed-tile-menu > button,
#fixed-tile-menu > nav a {
    height: 140px;
    width: 140px;
    padding: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-items: center;
}

#fixed-tile-menu > nav img {
    width: 74px;
    height: auto;
    margin: 0 auto;
}

#toggle-fixed-tile-menu {
    background-color: var(--primary-color);
    color: #FFF;
    height: 100%;
    font-size: 15px;
    font-weight: bold;
    width: 140px;
    text-transform: uppercase;
    z-index: 10;
    position: relative;

    img {
        width: 58px;
        height: auto;
        margin: 0 auto;
    }
}

#close-tiled-menu {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: 0;
    font-size: 20px;
}

/*
body.fixed-tile-menu-open {
    overflow: hidden;

    &, #header {
        padding-right: 17px;
    }

    &::after {
        content: ' ';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 26;
    }
}
*/

@media (--sm-viewport) {

    #cms-content .tiled-menu img {
        height: 42px;
        width: auto;
    }

    .tiled-menu {
        text-align: left;

        .row > div > a {
            padding: 15px;
            display: flex;
            align-items: center;
        }

        > .row > div {
            margin: 0;
            width: 100%;
            display: block;
        }

        .text {
            margin-left: 10px;
        }

        .subheader {
            margin: 0 0 10px 0;
        }

        .h3 {
            line-height: 1;
            font-size: 20px;
            margin: 0;
        }
    }

    .tiled-header-menu > a {
        width: 100%;
        display: flex;
        align-items: center;

        &::after {
            display: none;
        }
    }

    .tiled-header-menu img {
        height: 25px;
        width: auto;
        margin: 0;
    }

    .tiled-header-menu .subheader {
        margin: 0 0 0 15px;
    }

    #fixed-tile-menu > nav {
        padding-right: 70px;
        flex-wrap: wrap;
        top: -120px;
    }

    #fixed-tile-menu > button, #fixed-tile-menu > nav a {
        width: 50px;
        height: 90px;
        align-items: center;
        justify-content: center;
        padding: 3px;
    }

    #fixed-tile-menu > nav a {
        width: 90px;
        flex-grow: 1;
        flex-wrap: nowrap;
    }


    #fixed-tile-menu > nav > div {
        width: 220px;
    }

    #fixed-tile-menu {

        .toggle-text {
            display: none;
        }

        img {
            width: 29px;
        }
    }

    #fixed-tile-menu > nav img {
        width: 37px;
    }

}
