/* Primary, Secondary, Submit Button */
.btn-primary,
[type="submit"],
.submit {
    color: var(--white-color);
    background: var(--primary-color);
    border: 2px solid var(--primary-color);
    padding: 13px 30px;
    text-decoration: none !important;
    text-align: center;
    border-radius: 35px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 2rem;
    font-size: 15px;
    transition: opacity 200ms ease-in-out, background-color 300ms ease-in, color 300ms ease-out;
    display: inline-block;
    @media (--xs-viewport) {
        width: 100%;
        display: inline-block;
    }

    &:hover,
    &:focus {
        color: var(--primary-color);
        background: #FFF;
    }
}

.btn-secondary {
    color: inherit;
    border: 0;
    background: transparent;
    text-transform: uppercase;
    padding: 5px 0 6px;
    display: inline-block;
    position: relative;
    font-size: 12px;
    letter-spacing: 3px;
    font-weight: bold;

    &::after {
        content: ' ';
        display: block;
        height: 1px;
        background-color: var(--primary-textcolor);
        left: 0;
        right: 0;
        position: absolute;
        bottom: 0;
        transition: 200ms ease;
        letter-spacing: 3px;
    }

    &:hover,
    &:focus {
        color: var(--primary-color);
        text-decoration: none;

        &::after {
            height: 3px;
            background-color: var(--primary-color);
        }
    }
}


input, textarea, select, .submit, button {
    font-size: 1.6rem;
    line-height: 2rem;
    color: var(--black-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    border: none;
    box-sizing: border-box;
}

input[type="checkbox"],
input[type="radio"],
input[type="hidden"] {
    line-height: normal;
}

select {
    background: svg-load('../../../../../node_modules/@fortawesome/fontawesome-pro/svgs/light/chevron-down.svg', fill=var(--primary-textcolor)) calc(100% - 20px) 50% no-repeat;
    background-size: 20px auto;
    cursor: pointer;
}

input[type="text"], input[type="email"], textarea, input[type="password"], input[type="file"], input[type="date"], select, .formInputTxt {
    /*background: transparent;*/
    padding: 24px 15px 16px;
    max-width: var(--form-field-width);
    width: 100%;
    border: 1px solid var(--border-dark-color);
    color: var(--primary-textcolor);
    transition: border-color 500ms ease-in-out;

    &:focus {
        border-color: var(--texthover-color);
    }
}

input::placeholder {
}

/* Radio and Checkbox Buttons */
input[type=checkbox]:not(old),
input[type=radio]:not(old) {
    width: 3.2rem !important;
    height: 3.2rem;
    margin: 0;
    padding: 0;
    opacity: 0;
    left: 0;
    top: 0;
    position: absolute;

    & + label {
        position: relative;
        cursor: pointer;
        width: calc(100% - 3.2rem) !important;
        max-width: calc(var(--form-field-width) - 3.2rem) !important;
        margin-left: 3.2rem;
        font-size: 1.6rem;
        line-height: 3.2rem;
        display: inline-block;
        padding-left: 8px;
        margin-bottom: 5px;
        transition: 300ms color ease-in-out;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: -3.2rem;
            top: 0;
            width: 3.2rem;
            height: 3.2rem;
            border: 1px solid var(--border-color);
            line-height: 3.2rem;
            pointer-events: none;
            cursor: pointer;
            background-color: var(--background-color);
            transition: background-color 300ms ease-in-out;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: -2.4rem;
            top: 25%;
            width: 1.6rem;
            height: 0.8rem;
            line-height: 3.2rem;
            background: transparent;
            border: 2px solid var(--white-color);
            border-top: none;
            border-right: none;
            transform: rotate(-45deg);
            border-radius: 0;
            margin-top: 0;
            opacity: 0;
        }
    }

    &:checked {
        & + label {
            &::after {
                opacity: 1;
            }
        }
    }
}

input[type=radio]:not(old) + label::before {
    border-radius: 100%;
}

input[type=radio]:not(old) + label::after {
    border-radius: 100%;
    background: var(--white-color);
    border: none;
    width: 1.2rem;
    height: 1.2rem;
    top: calc(100% / 3);
    left: -2.2rem;
}

/* Skiplinks */
#skiplinks {
    position: fixed;
    top: 50px;
    width: 230px;
    z-index: 1001;

    a {
        color: var(--white-color);
        background: var(--primary-color);
        font-weight: bold;
        font-size: 2rem;
        padding: 10px 20px;
        display: inline-block;
        text-decoration: none;
        margin: 10px;
        position: absolute;
        min-width: 160px;

        &::after {
            background: svg-load('../../../../../node_modules/@fortawesome/fontawesome-pro/svgs/light/chevron-right.svg', fill=var(--white-color)) no-repeat center;
            background-size: 22px !important;
            content: '';
            padding-left: 10px;
        }
    }
}

.big-link-pdf,
.big-link-image {
    position: relative;
    padding-left: 50px;
    margin-top: 10px;
    margin-bottom: 10px;

    &::before {
        display: inline-block;
        font-family: FontAwesome;
        position: absolute;
        font-size: 36px;
        left: 0;
        top: -10px;
    }

    &::after {
        left: 50px;
    }
}

.big-link-pdf {
    &::before {
        content: '\f1c1';
    }
}

.big-link-image {
    &::before {
        content: '\f1c5';
    }
}
