#header {
    border-top: 9px solid var(--primary-color);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;

    > .container {
        &::before,
        &::after {
            display: none;
        }
    }

    .container > .row {
        display: flex;
    }

    background: #FFF;

    @media (--sm-viewport) {
        &.menuOpen {
            .container {
                .language {
                    order: 3;

                    #language-nav {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }

            .mobileMenu .mobileMenuButton {
                &::before {
                    content: '\f00d';
                }

                &:hover,
                &:focus {
                    &::before {
                        color: var(--primary-color);
                    }
                }
            }
        }

        .container {
            height: 100%;
        }
    }

    .logo-link {
        max-width: 120px;
        display: inline-block;
        height: auto;
        width: 100%;
        padding: 30px 0;
        position: absolute;
        background: #FFF;
        transition: all 300ms ease;

        img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            display: block;
        }

        &::before,
        &::after {
            content: ' ';
            display: block;
            background: inherit;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
        }

        &::before {
            left: -59px;
        }

        &::after {
            right: -59px;
        }
    }

    .language,
    .meta {
        margin: 0 -10px;
        float: right;
        @media (--sm-viewport) {
            margin: 0 !important;
        }

        & + .meta {
            margin-right: 10px;
        }
    }

    #meta-nav,
    #language-nav {
        margin: 0;
        padding: 10px 0 0;
        display: flex;
        @media (--sm-viewport) {
            border-top: 5px solid var(--primary-color);
            display: none;
            flex-direction: row;
            margin: 0 -15px !important;
            padding: 0;
        }

        li {
            margin: 0;
            @media (--sm-viewport) {
                width: 100%;
                display: inline-block;
                position: relative;
                margin-bottom: 0;
                border-bottom: 1px solid var(--primary-color);
            }

            a {
                padding: 2px 10px;
                background-size: 18px 12px;
                background-repeat: no-repeat;
                background-position: 10px center;
                @media (--sm-viewport) {
                    text-align: left;
                    padding: 10px 15px;
                    display: block;
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                    color: var(--primary-color);
                }

                &[hreflang] {
                    &::before {
                        content: '';
                        width: 20px;
                        height: 12px;
                        margin-right: 5px;
                        display: inline-block;
                        background-size: contain;
                        background-repeat: no-repeat;
                        text-align: center;
                        @media (--sm-viewport) {
                            width: 16px;
                            height: 12px;
                        }
                    }
                }
            }
        }
    }

    #main-nav {
        padding: 34px 0;
        margin: 0;
        display: flex;
        width: 100%;
        justify-content: space-between;
        @media (--sm-viewport) {
            display: block;
            border-bottom: 1px solid var(--primary-color);
        }

        li {
            display: inline-block;
            position: relative;
            margin-bottom: 0;
            @media (--sm-viewport) {
                width: 100%;
                &:not(:last-child) {
                    border-bottom: 1px solid var(--primary-color);
                }
            }

            &.hasSub {
                & > a {
                    position: relative;
                    @media (--sm-viewport) {
                        &::after {
                            content: '\f107';
                            width: 30px;
                            height: 30px;
                            display: block;
                            position: absolute;
                            right: 10px;
                            top: 50%;
                            transform: translateY(-50%);
                            font-family: FontAwesome;
                            font-size: 26px;
                            line-height: 30px;
                            text-align: center;
                        }
                    }

                    &[aria-expanded="true"] {
                        @media (--sm-viewport) {
                            &::after {
                                content: '\f106';
                            }
                        }

                        & + ul {
                            display: flex;
                        }
                    }

                    & + ul {
                        display: none;
                    }
                }
            }

            a {
                display: block;
                text-align: center;
                font-size: 16px;
                font-weight: bold;
                color: #333333;
                @media (--sm-viewport) {
                    text-align: left;
                    padding: 15px 45px 15px 15px;
                }

                &:hover,
                &:focus,
                &.active {
                    text-decoration: none;
                    color: var(--primary-color);
                }
            }

            ul {
                position: absolute;
                left: 0;
                margin: 0;
                flex-direction: column;
                background: #FFF;
                border-top: 2px solid var(--primary-color);
                box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
                top: 54px;
                z-index: 1;
                padding: 0;
                @media (--sm-viewport) {
                    position: static;
                    display: none;
                    padding: 0;
                    background: var(--white-color);
                    border-top: 1px solid var(--primary-color);
                }

                li {
                    a {
                        color: var(--primary-textcolor);
                        padding: 8px 45px 8px 35px;
                    }
                }
            }
        }
    }

    .mobileMenu {
        position: fixed;
        top: 0;
        width: 80px;
        height: 80px;
        right: 0;

        .mobileMenuButton {
            display: none;
            position: absolute;
            top: 32px;
            right: 20px;
            width: 40px;
            height: 40px;
            font-size: 0;
            background: transparent;
            border-radius: 0;
            border: none;
            text-align: center;
            cursor: pointer;
            @media (--sm-viewport) {
                display: block;
            }

            &::before {
                content: '\f0c9';
                width: 100%;
                height: 100%;
                display: inline-block;
                font-family: FontAwesome;
                font-size: 25px;
                color: var(--primary-color);
                line-height: 40px;
            }

            &:hover,
            &:focus {
                text-decoration: none;
                background: var(--white-color);

                &::before {
                    color: var(--primary-color);
                }
            }
        }
    }
}

#header-intro .keyvisual {
    width: 1257px;
    max-width: 100%;
    margin: 0 auto;

    .h1 {
        background-color: rgba(255, 255, 255, 0.6);
        margin-top: 10px;
        display: inline-block;
        padding: 4px 15px;
    }

    .subheader,
    .subheader p {
        margin: 0;
    }
}

.scrolled #header {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
}

.scrolled #header .logo-link {
    max-width: 50px;
    padding: 4px 0;
}

@media (--sm-viewport) {
    #header .container > .row {
        display: block;
    }

    #header .logo-wrap {
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .scrolled #header .logo-link {
        padding: 0;
    }

    #header .logo-link, #header .logo-link {
        position: static;
        width: 50px;
        padding: 0;

        &::before,
        &::after {
            display: none;
        }
    }

    #header > .container nav {
        display: none;
        position: fixed;
        top: 90px;
        left: 0;
        right: 0;
        bottom: 0;
        padding-bottom: 40px;
        background-color: #FFF;
        z-index: 101;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
    }

    #header.menuOpen nav {
        display: block;
    }

    #header #main-nav {
        padding: 0;
    }

    .tz-link-img {
        display: none;
    }
}

.search-link {
    .text {
        font-size: 0;
        width: 0;
        height: 0;
        overflow: hidden;
    }

    @media (--sm-viewport) {
        .fa {
            display: none;
        }

        .text {
            width: auto;
            height: auto;
            font-size: inherit;
        }
    }
}


